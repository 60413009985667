.customPhoneInput {
    input {
        all: unset;
        display: block;
        width: 100%;
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1.5;
        color: var(--ct-input-color);
        background-color: var(--ct-input-bg);
        background-clip: padding-box;
        appearance: none;
        border-radius: 0.2rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
}
