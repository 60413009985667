.overlay {
    position: fixed;
    inset: 0;
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1999;
    & :global(.spinner-border) {
        width: 56px;
        height: 56px;
    }
}
.wrapper {
    position: relative;
    .spinnerContent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
    }
}
