

.codeInput{
    width: 80%;
    margin-bottom: 1rem;
}
.code{
    display: inline-block;
    padding: 2px;
    border: 1px solid #9e9e9e;
}